import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';
import { BreadcrumbModule } from 'angular-crumbs';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { FormsModule } from '@angular/forms';
import { SlickCarouselModule } from 'ngx-slick-carousel';
import { NgxSliderModule } from '@angular-slider/ngx-slider';
import { CountUpModule } from 'ngx-countup';
import { NgxPaginationModule } from 'ngx-pagination';

import { PreloaderComponent } from './preloader/preloader.component';
import { HeaderComponent } from './header/header.component';
import { HeaderTwoComponent } from './header-two/header-two.component';
import { BannerComponent } from './banner/banner.component';
import { FooterComponent } from './footer/footer.component';
import { BreadcrumbsComponent } from './breadcrumbs/breadcrumbs.component';
import { GallerySliderComponent } from './gallery-slider/gallery-slider.component';
import { ServiceSidebarComponent } from './service-sidebar/service-sidebar.component';
import { MedicalTestOrderSidebarComponent } from './medical-test-order-sidebar/medical-test-order-sidebar.component';
import { MobileMenuComponent } from './mobile-menu/mobile-menu.component';
import { CounterComponent } from './counter/counter.component';
import { WhyUsComponent } from './why-us/why-us.component';
import { WorkProcessComponent } from './work-process/work-process.component';
import { ClientsComponent } from './clients/clients.component';
import { NewsletterComponent } from './newsletter/newsletter.component';
import { SearchFormComponent } from './search-form/search-form.component';
import { ServicesComponent } from './services/services.component';
import { ServicesImageComponent } from './services-image/services-image.component';
import { ServiceBlockComponent } from './service-block/service-block.component';

@NgModule({
  declarations: [
    PreloaderComponent,
    HeaderComponent,
    HeaderTwoComponent,
    FooterComponent,
    BreadcrumbsComponent,
    GallerySliderComponent,
    ServiceSidebarComponent,
    MedicalTestOrderSidebarComponent,
    MobileMenuComponent,
    CounterComponent,
    WhyUsComponent,
    WorkProcessComponent,
    BannerComponent,
    ClientsComponent,
    NewsletterComponent,
    SearchFormComponent,
    ServicesComponent,
    ServicesImageComponent,
    ServiceBlockComponent
  ],
  imports: [
    CommonModule,
    RouterModule,
    NgbModule,
    BreadcrumbModule,
    FormsModule,
    SlickCarouselModule,
    NgxSliderModule,
    CountUpModule,
    NgxPaginationModule
  ],
  exports:[
    PreloaderComponent,
    HeaderComponent,
    HeaderTwoComponent,
    BannerComponent,
    FooterComponent,
    BreadcrumbsComponent,
    GallerySliderComponent,
    ServiceSidebarComponent,
    MedicalTestOrderSidebarComponent,
    CounterComponent,
    WhyUsComponent,
    WorkProcessComponent,
    ClientsComponent,
    NewsletterComponent,
    SearchFormComponent,
    ServicesComponent,
    ServicesImageComponent,
    ServiceBlockComponent
  ]
})
export class SharedModule { }
