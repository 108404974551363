import { Component, OnInit } from '@angular/core';
import { DoctorHelperService } from 'src/app/helper/doctor/doctor-helper.service';

@Component({
  selector: 'app-order-details',
  templateUrl: './order-details.component.html',
  styleUrls: ['./order-details.component.scss']
})

// TODO: Remove the extended classes as well as related code and instead get data from the backend
export class OrderDetailsComponent extends DoctorHelperService implements OnInit {
  data: any;

  constructor() {
    // @ts-ignore
    super();
  }

  ngOnInit(): void {
    this.data = this.doctordetails;
  }

}
