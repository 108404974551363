<div class="section section-padding">
  <div class="container">
    <div class="row">
      <!-- Data -->
      <div class="col-lg-4 col-md-6"
           *ngFor="let item of serviceblock | paginate: { itemsPerPage: 6, currentPage: page }">
        <div class="sigma_service style-18 has-bg">
          <div class="sigma_service-thumb">
            <i [class]="item.icon"></i>
          </div>
          <div class="sigma_service-body">
            <h5>
              <a routerLink="/service-details/{{item.id}}">{{item.title}}</a>
            </h5>
            <p>{{item.shorttext | slice:0:70}}</p>
          </div>
        </div>
      </div>
      <!-- Data -->
    </div>
    <!-- Pagination -->
    <pagination-controls (pageChange)="page = $event" [responsive]="isResponsive" previousLabel="<" nextLabel=">">
    </pagination-controls>
    <!-- Pagination -->
  </div>
</div>
