<app-breadcrumbs></app-breadcrumbs>
<div class="sidebar-style-9">
  <div class="section">
    <div class="container">
      <form>
        <div class="row">
          <div class="col-lg-8">
            <div class="sigma_form style-7">
              <div class="form-block">
                <h4>Your Information:</h4>
                <div class="row">
                  <div class="col-lg-6">
                    <div class="form-group">
                      <i class="fal fa-user"></i>
                      <input type="text" placeholder="Patient Name">
                    </div>
                  </div>
                  <div class="col-lg-6">
                    <div class="form-group">
                      <i class="fal fa-at"></i>
                      <input type="email" placeholder="Email">
                    </div>
                  </div>
                  <div class="col-lg-6">
                    <div class="form-group">
                      <i class="fal fa-calendar-alt"></i>
                      <input type="text" data-provide="datepicker"
                             placeholder="Date of Birth">
                    </div>
                  </div>
                  <div class="col-lg-6">
                    <div class="form-group">
                      <i class="fal fa-phone"></i>
                      <input type="text" placeholder="Phone Number">
                    </div>
                  </div>
                </div>
              </div>
              <div class="form-block d-flex">
                <h4 class="mb-0 mr-4">Gender:</h4>
                <div class="d-flex align-items-center">
                  <input type="radio" id="radio" value="Male">
                  <label class="mb-0" for="radio">Male</label>
                </div>
                <div class="d-flex align-items-center ml-4">
                  <input type="radio" id="radio2" value="Female">
                  <label class="mb-0" for="radio2">Female</label>
                </div>
              </div>
              <div class="form-block">
                <div class="row">
                  <div class="col-12">
                    <div class="form-group">
                      <select>
                        <option value="1" selected>Select Hospital</option>
                        <option value="2">Hospital 1</option>
                        <option value="3">Hospital 2</option>
                        <option value="4">Hospital 3</option>
                      </select>
                    </div>
                  </div>
                  <div class="col-12">
                    <div class="form-group">
                      <select>
                        <option value="1" selected>Select Service</option>
                        <option value="2">Service 1</option>
                        <option value="3">Service 2</option>
                        <option value="4">Service 3</option>
                      </select>
                    </div>
                  </div>
                  <div class="col-12">
                    <div class="form-group">
                      <i class="fal fa-calendar-alt"></i>
                      <input type="text" data-provide="datepicker"
                             placeholder="Select Date">
                    </div>
                  </div>
                  <div class="col-12">
                    <div class="form-group">
                      <i class="fal fa-user-md"></i>
                      <input type="text" placeholder="Select Doctor">
                    </div>
                  </div>
                  <div class="col-12">
                    <div class="form-group">
                      <textarea rows="7" value="" placeholder="Note To The Doctor(Optional)"></textarea>
                    </div>
                  </div>
                </div>
              </div>
              <div class="form-block mb-0">
                <h4>Payment Information:</h4>
                <div class="row">
                  <div class="col-12">
                    <div class="form-group">
                      <label>Name On Card</label>
                      <input type="text" placeholder="Dorothy Schneider">
                    </div>
                  </div>
                  <div class="col-12">
                    <div class="form-group">
                      <label>Card Number</label>
                      <div class="payment-card-wrapper d-block d-sm-flex align-items-center">
                        <input type="text" placeholder="xxxx-xxxx-xxxx-xxxx">
                        <div class="card-image">
                          <img src="../../../../assets/img/book-apppointment/243x50.png" alt="img">
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="col-lg-6">
                    <div class="form-group">
                      <label>Expiration Date</label>
                      <input type="text" placeholder="mm/yy"
                             data-provide="datepicker">
                    </div>
                  </div>
                  <div class="col-lg-6">
                    <div class="form-group">
                      <label>Security Code</label>
                      <input type="text" placeholder="CCV">
                    </div>
                  </div>
                </div>
              </div>
              <div class="d-flex align-items-center mt-2">
                <input type="checkbox" id="checkbox" value="accept">
                <label class="mb-0" for="checkbox">I accept <a href="#">Terms</a> and <a
                  href="#">conditions</a> and general policy</label>
              </div>
            </div>
          </div>
          <div class="col-lg-4">
            <div class="sidebar style-10 mt-5 mt-lg-0">
              <!-- Booking Widget -->
              <div class="widget widget-booking">
                <h5 class="widget-title">Test Summary</h5>
                <ul>
                  <li class="d-flex align-items-center justify-content-between">
                    <span>Date</span>
                    <span>07/10/2021</span>
                  </li>
                  <li class="d-flex align-items-center justify-content-between">
                    <span>Time</span>
                    <span>08:30 PM</span>
                  </li>
                  <li class="d-flex align-items-center justify-content-between">
                    <span>Doctor Name</span>
                    <span>Dr. Joseph Doe</span>
                  </li>
                </ul>
                <hr>
                <ul>
                  <li class="d-flex align-items-center justify-content-between">
                    <span>Lorem ipsum dolor</span>
                    <span>$80</span>
                  </li>
                  <li class="d-flex align-items-center justify-content-between">
                    <span>Lorem ipsum dolor</span>
                    <span>$140</span>
                  </li>
                </ul>
                <hr>
                <ul>
                  <li class="d-flex align-items-center justify-content-between">
                    <span class="secondary-color"><b>Total</b></span>
                    <span class="secondary-color"><b>$220</b></span>
                  </li>
                  <li class="d-flex align-items-center justify-content-between">
                    <button type="submit" class="sigma_btn btn-block btn-sm mt-4">
                      Confirm and Pay
                      <i class="fal fa-arrow-right ml-3"></i>
                    </button>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </form>
    </div>
  </div>
</div>
