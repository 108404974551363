import { Component } from '@angular/core';
import { ServiceHelperService } from 'src/app/helper/service/service-helper.service';

@Component({
  selector: 'app-service-block',
  templateUrl: './service-block.component.html',
  styleUrls: ['./service-block.component.scss']
})

// TODO: Remove the extended classes as well as related code and instead get data from the backend
export class ServiceBlockComponent extends ServiceHelperService {
  isResponsive: boolean = true;
  constructor() {
    // @ts-ignore
    super();
  }
}
