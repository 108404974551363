import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
/*import { AuthGuard } from '../guards/auth.guard';
import { Role } from '../models';*/
import {
  HomeComponent,
  AboutComponent,
  OurServicesComponent,
  MedicalTestsComponent,
  MedicalTestDetailsComponent,
  AddOrderComponent,
  FaqComponent,
  OrdersComponent,
  OrderDetailsComponent,
  AddMedicalTestComponent,
  ContactComponent,
} from './index';

const routes: Routes = [
  { path: '', component: HomeComponent },
  { path: 'home', component: HomeComponent },
  { path: 'about', component: AboutComponent, data: { breadcrumb: 'About Us' } },
  { path: 'our-services', component: OurServicesComponent, data: { breadcrumb: 'What we do' } },
  { path: 'medical-tests', component: MedicalTestsComponent, data: { breadcrumb: 'Medical Tests' }},
  { path: 'faq', component: FaqComponent, data: { breadcrumb: 'FAQ' }},
  { path: 'medical-test-details', component: MedicalTestDetailsComponent, data: { breadcrumb: 'Test Details' }},
  { path: 'add-order', component: AddOrderComponent, data: { breadcrumb: 'Order Test' }},
  { path: 'orders', component: OrdersComponent, data: { breadcrumb: 'Test Orders' }},
  { path: 'order-details', component: OrderDetailsComponent, data: { breadcrumb: 'Order Details' }},
  { path: 'add-medical-test', component: AddMedicalTestComponent, data: { breadcrumb: 'Add Test' }},
  { path: 'contact', component: ContactComponent, data: { breadcrumb: 'Contact Us' }},
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class PagesRoutingModule {
}


