import { Component, OnInit } from '@angular/core';
import data from "../../data/whyus.json";

@Component({
  selector: 'app-why-us',
  templateUrl: './why-us.component.html',
  styleUrls: ['./why-us.component.scss']
})
// TODO: Remove the static code from the json file and instead get data from the backend
export class WhyUsComponent implements OnInit {
  whyus: any[] = data;
  constructor() { }

  ngOnInit(): void {
  }

}
