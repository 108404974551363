export * from './home/home.component';
export * from './about/about.component';
export * from './our-services/our-services.component';
export * from './faq/faq.component';
export * from './medical-tests/medical-tests.component';
export * from './medical-test-details/medical-test-details.component';
export * from './add-order/add-order.component';
export * from './orders/orders.component';
export * from './order-details/order-details.component';
export * from './order-details/order-details.component';
export * from './add-medical-test/add-medical-test.component';
export * from './contact/contact.component';
