import { Component, OnInit } from '@angular/core';
import { ContactHelperService } from 'src/app/helper/contact/contact-helper.service';
import { Contact } from '../../models/contact/contact';

@Component({
  selector: 'app-contact',
  templateUrl: './contact.component.html',
  styleUrls: ['./contact.component.scss']
})
export class ContactComponent implements OnInit {

  // TODO: Replace the code after connecting to the backend
  model = new Contact;
  submitted = false;
  error: {} | undefined;

  constructor(private contactService: ContactHelperService) { }

  ngOnInit(): void {
  }

  onSubmit() {
    this.submitted = true;
    return this.contactService.contactForm(this.model).subscribe(
        data => this.model = data,
        error => this.error = error
    );
  };

  resolved(captchaResponse: string) {
    console.log(`Resolved response token: ${captchaResponse}`);
  }
}
