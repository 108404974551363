import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { PagesRoutingModule } from './pages-routing.module';
import { SharedModule } from '../components/shared.module';
import { NgbModule } from "@ng-bootstrap/ng-bootstrap";
import { NgxPaginationModule } from 'ngx-pagination';
import { RecaptchaModule, RecaptchaFormsModule } from 'ng-recaptcha';

import { NgxPageScrollCoreModule } from 'ngx-page-scroll-core';
import { NgxPageScrollModule } from 'ngx-page-scroll';

/*import { SweetAlert2Module } from '@sweetalert2/ngx-sweetalert2';
import { AutocompleteLibModule } from 'angular-ng-autocomplete';
import { ImageCropperModule } from 'ngx-image-cropper';
import { NgxIntlTelInputModule } from 'ngx-intl-tel-input';
import { DataTablesModule } from 'angular-datatables';
import { TooltipModule } from 'ngx-bootstrap/tooltip';
import { AngularEditorModule } from '@kolkov/angular-editor';
import { DateDifferencePipe } from '../pipes/date-difference.pipe';
import { MoneyFormatPipe } from '../pipes/money-format.pipe';
import { TabsModule } from 'ngx-bootstrap/tabs';*/

import {
  HomeComponent,
  AboutComponent,
  OurServicesComponent,
  MedicalTestsComponent,
  MedicalTestDetailsComponent,
  FaqComponent,
  AddOrderComponent,
  OrdersComponent,
  OrderDetailsComponent,
  AddMedicalTestComponent,
  ContactComponent,

  /*FavouriteJobComponent,
 JobDetailsComponent,
 PostAJobComponent,
 EditJobComponent,
 CandidateListComponent,
 CandidateDetailsComponent,
 SingleResumeComponent,
 SubmitResumeComponent,
 PricingComponent,
 CandidateDashboardComponent,
 EmployerDashboardComponent,
 CompanyListComponent,
 EmployerDetailsComponent,
 ProfileComponent,
 SingleProfileComponent,
 FaqComponent,
 TermsAndConditionsComponent,
 PrivacyPolicyComponent,
 ContactComponent,
 BlogDetailsComponent,
 BlogComponent,
 SettingsComponent,
 EditUserComponent,
 AdminComponent,
 CommentComponent,
 UnsubscribeRecommendedJobsComponent*/
} from './index';

/*import {
  SelectComponent,
  CardComponent,
  InputTextComponent,
  SliderComponent,
  AccordionComponent,
  TableComponent,
  StepperComponent,
  StepComponent,
  ImageCropperComponent,
  HeaderComponent,
  RichTextEditorComponent,
  SearchFormComponent
} from '../components';*/

@NgModule({
  declarations: [
    // ProfileComponent,
    HomeComponent,
    AboutComponent,
    OurServicesComponent,
    MedicalTestsComponent,
    MedicalTestDetailsComponent,
    FaqComponent,
    AddOrderComponent,
    OrdersComponent,
    OrderDetailsComponent,
    AddMedicalTestComponent,
    ContactComponent,

    /*FavouriteJobComponent,
    JobDetailsComponent,
    PostAJobComponent,
    EditJobComponent,
    CandidateListComponent,
    CandidateDetailsComponent,
    SingleResumeComponent,
    SubmitResumeComponent,
    PricingComponent,
    CandidateDashboardComponent,
    EmployerDashboardComponent,
    CompanyListComponent,
    EmployerDetailsComponent,
    ProfileComponent,
    SingleProfileComponent,
    FaqComponent,
    TermsAndConditionsComponent,
    PrivacyPolicyComponent,
    ContactComponent,
    BlogDetailsComponent,
    BlogComponent,
    SettingsComponent,
    EditUserComponent,
    AdminComponent,
    SelectComponent,
    CardComponent,
    InputTextComponent,
    SliderComponent,
    AccordionComponent,
    TableComponent,
    CommentComponent,
    StepperComponent,
    StepComponent,
    ImageCropperComponent,
    HeaderComponent,
    RichTextEditorComponent,
    SearchFormComponent,
    DateDifferencePipe,
    MoneyFormatPipe,
    UnsubscribeRecommendedJobsComponent*/
  ],
  imports: [
    CommonModule,
    FormsModule,
    NgbModule,
    ReactiveFormsModule,
    PagesRoutingModule,
    SharedModule,
    NgxPaginationModule,
    RecaptchaModule,
    RecaptchaFormsModule,

    /*SweetAlert2Module,
    AutocompleteLibModule,
    ImageCropperModule,
    NgxIntlTelInputModule,

    DataTablesModule,
    AngularEditorModule,
    TooltipModule.forRoot(),
    TabsModule.forRoot()*/
  ],
  exports: [
    /*SelectComponent,
    CardComponent,
    InputTextComponent,
    AccordionComponent,
    ImageCropperModule,
    TableComponent,
    StepperComponent,
    StepComponent,
    ImageCropperComponent,
    HeaderComponent*/
  ],
  // providers: [DateDifferencePipe]
})
export class PagesModule { }
