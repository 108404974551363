import { Component } from '@angular/core';
import { ServiceHelperService } from '../../helper/service/service-helper.service';

@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.scss']
})

// TODO: Remove the extended classes and instead get data from the backend
export class FooterComponent extends ServiceHelperService {
  data: any[] = this.serviceblock;
  constructor() {
    // @ts-ignore
    super();
  }
}
