import { Component } from '@angular/core';
import { ServiceHelperService } from 'src/app/helper/service/service-helper.service';

@Component({
  selector: 'app-services-image',
  templateUrl: './services-image.component.html',
  styleUrls: ['./services-image.component.scss']
})
// TODO: Remove the extended classes as well as related code and instead get data from the backend
export class ServicesImageComponent extends ServiceHelperService {
  constructor() {
    // @ts-ignore
    super();
  }
}
