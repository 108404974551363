<app-breadcrumbs></app-breadcrumbs>
<div class="section section-padding">
  <div class="container">
    <div class="row">
      <div class="col-lg-4 col-md-6">
        <div class="sigma_info style-24">
          <div class="sigma_info-title">
            <span class="sigma_info-icon bg-primary-1 text-white">
              <i class="flaticon-hospital"></i>
            </span>
          </div>
          <div class="sigma_info-description">
            <h5>Our Address</h5>
            <p>PSD Building, 2 Tower St, United States.</p>
          </div>
        </div>
      </div>
      <div class="col-lg-4 col-md-6">
        <div class="sigma_info style-24">
          <div class="sigma_info-title">
            <span class="sigma_info-icon bg-primary-1 text-white">
              <i class="flaticon-call"></i>
            </span>
          </div>
          <div class="sigma_info-description">
            <h5>Our Phone</h5>
            <p>Telephone: 0029129102320</p>
            <p>Mobile: 000 2324 39493</p>
          </div>
        </div>
      </div>
      <div class="col-lg-4 col-md-6">
        <div class="sigma_info style-24">
          <div class="sigma_info-title">
            <span class="sigma_info-icon bg-primary-1 text-white">
              <i class="flaticon-envelope"></i>
            </span>
          </div>
          <div class="sigma_info-description">
            <h5>Our Email</h5>
            <p>Main Email: example@example.com</p>
            <p>Inquiries: example@example.com</p>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
<div class="section pt-0">
  <div class="container">
    <div class="section-title centered">
      <span class="subtitle">Call to Action</span>
      <h3 class="title">Make a Request</h3>
    </div>
    <div class="sigma_form style-2">
      <form (ngSubmit)="onSubmit()" #contactForm="ngForm">
        <div class="row">
          <div class="col-lg-6">
            <div class="form-group">
              <input type="text" placeholder="Enter Full Name" name="name" [(ngModel)]="model.name" required>
            </div>
          </div>
          <div class="col-lg-6">
            <div class="form-group">
              <input type="email" placeholder="Email Address" name="email" [(ngModel)]="model.email" required>
            </div>
          </div>
          <div class="col-lg-6">
            <div class="form-group">
              <input type="number" placeholder="Phone number" name="phone" [(ngModel)]="model.phone" required>
            </div>
          </div>
          <div class="col-lg-6">
            <div class="form-group">
              <input type="text" placeholder="Subject" name="subject" [(ngModel)]="model.subject" required>
            </div>
          </div>
          <div class="col-12">
            <div class="form-group">
              <textarea rows="10" placeholder="Enter Message" name="message" [(ngModel)]="model.message" required>
            </textarea>
            </div>
          </div>
          <re-captcha (resolved)="resolved($event)" name="recaptcha" [(ngModel)]="model.recaptcha"
                      siteKey="6LdxUhMaAAAAAIrQt-_6Gz7F_58S4FlPWaxOh5ib" size="invisible"></re-captcha>
          <div class="col-12 text-center">
            <button type="submit" [disabled]="!contactForm.form.valid">Submit Request</button>
            <!-- Form Messages -->
            <div class="service-error mt-4 w-100" *ngIf="error">
              <ngb-alert type="danger" class="mb-0 w-100" [dismissible]="false">
                <strong>Oops!</strong> Something bad happened. Please try again later.
              </ngb-alert>
            </div>
            <div [hidden]="!submitted" class="contact-message w-100">
              <div *ngIf="model.id" class="contact-success w-100 mt-4">
                <ngb-alert type="success" class="mb-0 w-100" [dismissible]="false">
                  <strong>Success!</strong> Contact form has been successfully submitted.
                </ngb-alert>
              </div>
            </div>
            <!-- Form Messages -->
          </div>
        </div>
      </form>
    </div>
  </div>
</div>
<div class="sigma_contact-map">
  <iframe title="contactmap" src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2624.9914406081493!2d2.292292615201654!3d48.85837360866272!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x47e66e2964e34e2d%3A0x8ddca9ee380ef7e0!2sEiffel%20Tower!5e0!3m2!1sen!2sin!4v1571115084828!5m2!1sen!2sin" height="600" allowfullscreen="">
  </iframe>
</div>
