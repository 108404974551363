import { Component, OnInit } from '@angular/core';
import { ServiceHelperService } from 'src/app/helper/service/service-helper.service';

@Component({
  selector: 'app-medical-test-details',
  templateUrl: './medical-test-details.component.html',
  styleUrls: ['./medical-test-details.component.scss']
})

// TODO: Remove the extended classes as well as related code and instead get data from the backend
export class MedicalTestDetailsComponent extends ServiceHelperService {
  details = undefined;

  constructor() {
    // @ts-ignore
    super();
  }

  ngOnInit(): void {
    this.details = this.servicedetails;
    console.log('testing', this.servicedetails);
  }

}
