<div class="section section-padding sigma_service-sec style-16">
  <div class="container">

    <div style="color: red; font-weight: bold; margin-bottom: 48px;">TODO: Implement these as filters for the categories
      of tests
    </div>

    <div class="row">
      <!-- Data -->
      <div class="col-lg-3 col-md-4 col-sm-6" *ngFor="let item of data | slice:0:4">
        <div class="sigma_service style-16">
          <div class="sigma_service-thumb">
            <i [class]="item.icon"></i>
          </div>
          <div class="sigma_service-body">
            <h5>
              <a routerLink="/service-details/{{item.id}}">{{item.title}}</a>
            </h5>
            <p>{{item.shorttext | slice:0:70}}</p>
            <a routerLink="/service-details/{{item.id}}" class="btn-link primary-color">
              Read More
              <i class="far fa-long-arrow-alt-right"></i>
            </a>
          </div>
        </div>
      </div>
      <!-- Data -->
    </div>

    <div style="color: red; font-weight: bold;">TODO: Implement the categories of tests which the user should be able to
      filter here
    </div>

  </div>
</div>
