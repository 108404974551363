<div class="row">
  <!-- Data -->
  <div class="col-lg-4 col-md-6" *ngFor="let item of getRecentService()">
    <div class="sigma_service style-17">
      <div class="sigma_service-thumb">
        <img [src]="item.image" [alt]="item.title">
      </div>
      <div class="sigma_service-body">
        <h5>
          <a routerLink="/service-details/{{item.id}}">{{item.title}}</a>
        </h5>
        <p>{{item.shorttext | slice:0:95}}</p>
        <a routerLink="/service-details/{{item.id}}" class="btn-link primary-color">
          Read More
          <i class="fal fa-arrow-right"></i>
        </a>
      </div>
    </div>
  </div>
  <!-- Data -->
</div>
