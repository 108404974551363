import { Component } from '@angular/core';
import { ServiceHelperService } from '../../helper/service/service-helper.service';

@Component({
  selector: 'app-service-sidebar',
  templateUrl: './service-sidebar.component.html',
  styleUrls: ['./service-sidebar.component.scss']
})
// TODO: Remove the extended classes as well as related code and instead get data from the backend
export class ServiceSidebarComponent extends ServiceHelperService {
  constructor() {
    // @ts-ignore
    super();
  }
}
