import { Component } from '@angular/core';
import { ServiceHelperService } from 'src/app/helper/service/service-helper.service';

@Component({
  selector: 'app-services',
  templateUrl: './services.component.html',
  styleUrls: ['./services.component.scss']
})
// TODO: Remove the extended classes as well as related code and instead get data from the backend
export class ServicesComponent extends ServiceHelperService {
  data: any[] = this.serviceblock;
  constructor() {
    // @ts-ignore
    super();
  }
}
