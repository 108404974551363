<app-breadcrumbs></app-breadcrumbs>
<div class="section sigma_post-details" *ngFor="let item of data">
  <div class="container">
    <div class="row">
      <div class="col-lg-8">
        <div class="sigma_post-details-inner">
          <div class="entry-content">
            <div class="sigma_team style-17 mb-0">
              <div class="row no-gutters">
                <div class="col-md-4">
                  <div class="sigma_team-thumb">
                    <img [src]="item.image" [alt]="item.name">
                  </div>
                </div>
                <div class="col-md-8">
                  <div class="sigma_team-body">
                    <h5>
                      <a routerLink="/doctor-details/{{item.id}}">{{item.name}}</a>
                    </h5>
                    <div class="sigma_rating">
                      <ngb-rating [max]="5" [(rate)]="item.rating" [readonly]="true">
                        <ng-template let-fill="fill" let-index="index">
                          <i class="far fa-star" [class.fas]="fill === 100"></i>
                        </ng-template>
                      </ngb-rating>
                      <span class="ml-3">({{item.reviews.length}})</span>
                    </div>
                    <div class="sigma_team-categories">
                      <a routerLink="/doctor-details/{{item.id}}"
                         class="sigma_team-category">{{item.specialist}}</a>
                    </div>
                    <div class="sigma_team-info mt-4">
                      <span><i class="fal fa-phone"></i>{{item.phone}}</span>
                      <span><i class="fal fa-at"></i>{{item.email}}</span>
                      <span><i class="fal fa-building"></i>{{item.location}}</span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="detail-menu-list">
              <div class="row no-gutters">
                <div style="font-weight: bold; color: red;">
                  TODO: Implement the remarks and status of the order (timeline preferable) here. If clicked the content should replace the overview content</div>
                <div class="col-md-4">
                  <div class="menu nav-item">
                    <a pageScroll href="#overview" class="nav-link active p-0">Overview</a>
                  </div>
                </div>
                <div class="col-md-4">
                  <div class="menu nav-item">
                    <a pageScroll href="#contact" class="nav-link p-0">Status</a>
                  </div>
                </div>
                <div class="col-md-4">
                  <div class="menu nav-item border-0">
                    <a pageScroll href="#reviews" class="nav-link p-0">Remarks</a>
                  </div>
                </div>
              </div>
            </div>
            <div id="overview">
              <h4>Overview Of {{item.name}}</h4>
              <div [innerHtml]="item.htmltext"></div>
            </div>
            <div class="spacer">
            </div>
            <div id="contact">
              <h4>{{item.name}} Location &amp; Contact Information</h4>
              <div class="sigma_contact-wrapper">
                <div class="sigma_contact-map">
                  <iframe
                    src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2624.9914406081493!2d2.292292615201654!3d48.85837360866272!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x47e66e2964e34e2d%3A0x8ddca9ee380ef7e0!2sEiffel%20Tower!5e0!3m2!1sen!2sin!4v1571115084828!5m2!1sen!2sin"
                    height="600" allowfullscreen="">
                  </iframe>
                </div>
                <div class="sigma_contact-blocks">
                  <h5>Hospital Address</h5>
                  <div class="row">
                    <div class="col-md-4">
                      <div class="sigma_contact-block style-3">
                        <i class="fal fa-map-marker-alt icon"></i>
                        <div class="contact-block-inner">
                          <p>{{item.location}}</p>
                          <p class="mb-0">{{item.location}}</p>
                        </div>
                      </div>
                    </div>
                    <div class="col-md-4">
                      <div class="sigma_contact-block style-3 mt-3 mt-md-0">
                        <i class="fal fa-phone icon"></i>
                        <div class="contact-block-inner">
                          <p>{{item.phone}}</p>
                          <p class="mb-0">{{item.phone}}</p>
                        </div>
                      </div>
                    </div>
                    <div class="col-md-4">
                      <div class="sigma_contact-block style-3 mt-3 mt-md-0">
                        <i class="fal fa-globe icon"></i>
                        <div class="contact-block-inner">
                          <p>{{item.email}}</p>
                          <p class="mb-0">{{item.email}}</p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="spacer">
            </div>
            <div id="reviews">
              <h4>Patient Experience</h4>
              <!-- Data -->
              <div class="sigma_testimonial style-14" *ngFor="let review of item.reviews">
                <div class="sigma_testimonial-thumb" *ngFor="let user of getAuthor(review.user)">
                  <img [src]="user.image" [alt]="user.name">
                </div>
                <div class="sigma_testimonial-body" *ngFor="let user of getAuthor(review.user)">
                  <div class="d-flex align-items-center justify-content-between">
                    <div class="d-block d-sm-flex align-items-center">
                      <div class="sigma_author-block">
                        <h5>
                          {{user.name}}
                        </h5>
                      </div>
                      <div class="sigma_rating ml-sm-4 ml-0 mt-2 mt-sm-0">
                        <ngb-rating [max]="5" [(rate)]="review.rating" [readonly]="true">
                          <ng-template let-fill="fill" let-index="index">
                            <i class="far fa-star" [class.fas]="fill === 100"></i>
                          </ng-template>
                        </ngb-rating>
                        <span class="ml-3">({{review.rating}}/5)</span>
                      </div>
                    </div>
                    <span class="sigma_testimonial-date">{{review.commentdate}}</span>
                  </div>
                  <p>"{{review.comment}}"</p>
                </div>
              </div>
              <!-- Data -->
              <button type="button" class="sigma_btn">
                See More
                <i class="fal fa-arrow-right"></i>
              </button>
            </div>
          </div>
        </div>
      </div>
      <!-- Sidebar Start -->
      <div class="col-lg-4">
        <div class="sidebar style-10 mt-5 mt-lg-0">
          <!-- form Widget -->
          <div class="widget widget-form">
            <h5 class="widget-title">Order Summary</h5>
            <div class="widget-inner">
              <form>
                <label>Date</label>
                <div class="form-group">
                  <i class="fal fa-calendar-alt"></i>
                  <input type="text" name="date" data-provide="datepicker" placeholder="07/10/2021">
                </div>
                <label>Time</label>
                <div class="form-group mb-0">
                  <i class="far fa-clock"></i>
                  <input type="text" name="time" placeholder="08:30 PM">
                </div>
              </form>
            </div>
            <hr>
            <div class="widget-inner widget-service">
              <form>
                <div class="form-group">
                  <label>Choose Service</label>
                  <ul>
                    <li class="d-flex justify-content-between mb-3">
                      <div class="d-flex">
                        <input type="checkbox" id="checkbox" name="checkbox">
                        <label class="mb-0" for="checkbox">Lorem ipsum dolor</label>
                      </div>
                      <span>$80</span>
                    </li>
                    <li class="d-flex justify-content-between mb-3">
                      <div class="d-flex">
                        <input type="checkbox" id="checkbox1" name="checkbox">
                        <label class="mb-0" for="checkbox1">Lorem ipsum dolor</label>
                      </div>
                      <span>$80</span>
                    </li>
                    <li class="d-flex justify-content-between">
                      <div class="d-flex">
                        <input type="checkbox" id="checkbox2" name="checkbox">
                        <label class="mb-0" for="checkbox2">Lorem ipsum dolor</label>
                      </div>
                      <span>$80</span>
                    </li>
                  </ul>
                </div>
                <a routerLink="/appointment" class="sigma_btn btn-block btn-sm">
                  Book Appointment
                  <i class="fal fa-arrow-right ml-3"></i>
                </a>
              </form>
            </div>
          </div>
          <!-- form Widget 2 -->
          <div class="widget">
            <h5 class="widget-title">Get in Touch</h5>
            <div class="widget-inner">
              <form>
                <div class="form-group">
                  <i class="fal fa-user"></i>
                  <input type="text" name="fname" placeholder="Name" required>
                </div>
                <div class="form-group">
                  <i class="fal fa-envelope"></i>
                  <input type="email" name="email" placeholder="Email" required>
                </div>
                <div class="form-group">
                  <textarea name="message" rows="5" placeholder="Message" value="" required></textarea>
                </div>
                <button type="button" class="sigma_btn btn-block btn-sm">
                  Send Message
                  <i class="fal fa-arrow-right ml-3"></i>
                </button>
              </form>
            </div>
          </div>
          <!-- Contact Widget -->
          <div class="widget">
            <h5 class="widget-title">Contact</h5>
            <div class="widget-inner">
              <div class="sigma_info style-24 p-0 shadow-none">
                <div class="sigma_info-title">
                                    <span class="sigma_info-icon bg-primary-1 text-white">
                                        <i class="fal fa-phone"></i>
                                    </span>
                </div>
                <div class="sigma_info-description">
                  <h5>Our Phone</h5>
                  <p>Phone No.: {{item.phone}}</p>
                </div>
              </div>
              <div class="sigma_info style-24 p-0 shadow-none">
                <div class="sigma_info-title">
                                    <span class="sigma_info-icon bg-primary-1 text-white">
                                        <i class="fal fa-envelope-open-text"></i>
                                    </span>
                </div>
                <div class="sigma_info-description">
                  <h5>Our Email</h5>
                  <p>Inquiries: {{item.email}}</p>
                </div>
              </div>
              <div class="sigma_info style-24 p-0 shadow-none mb-0">
                <div class="sigma_info-title">
                                    <span class="sigma_info-icon bg-primary-1 text-white">
                                        <i class="fal fa-map-marker-alt"></i>
                                    </span>
                </div>
                <div class="sigma_info-description">
                  <h5>Our Address</h5>
                  <p>{{item.location}}</p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <!-- Sidebar End -->
    </div>
  </div>
</div>
