import { Component } from '@angular/core';
import { DoctorHelperService } from '../../helper/doctor/doctor-helper.service';
import { Options, LabelType } from "@angular-slider/ngx-slider";

@Component({
  selector: 'app-medical-test-order-sidebar',
  templateUrl: './medical-test-order-sidebar.component.html',
  styleUrls: ['./medical-test-order-sidebar.component.scss']
})
export class MedicalTestOrderSidebarComponent extends DoctorHelperService {
  minValue: number = 0;
  maxValue: number = 50;
  options: Options = {
    floor: 0,
    ceil: 50
  };

  constructor() {
    // @ts-ignore
    super();
  }
}
