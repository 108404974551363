import { Component, OnInit } from '@angular/core';
import { DoctorHelperService } from 'src/app/helper/doctor/doctor-helper.service';

@Component({
  selector: 'app-orders',
  templateUrl: './orders.component.html',
  styleUrls: ['./orders.component.scss']
})

// TODO: Remove the extended classes as well as related code and instead get data from the backend
export class OrdersComponent extends DoctorHelperService implements OnInit {
  isResponsive: boolean = true;

  constructor() {
    // @ts-ignore
    super();
  }

  ngOnInit(): void {
  }

}
